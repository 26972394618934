import { getCartCountFromCookie } from '../session'
import { addPromoBannerEffects } from './promoBanner'
import { getRewardsIcon } from './rewards'
import {
  setDisplayFlex,
  setWidth,
  setDisplayInlineBlock,
  setWhiteSpace,
  setTextAlignLeft,
  setTextAlignCenter,
  setAlignItemsCenter,
  setPadding,
  removeLeftPadding,
  removeRightPadding,
} from './styles'
import {
  desktopSignInHTML,
  desktopMyAccountHTML,
  mobileSignInHTML,
  mobileMyAccountHTML,
} from './htmlSnippets'
import { getEnvVar } from './../env'
import { DESKTOP_WIDTH } from './../constants'

const createMessageContainer = (width) => {
  const messageContainer = document.createElement('div')
  setWidth(messageContainer, width)
  setWhiteSpace(messageContainer, 'normal')
  setTextAlignLeft(messageContainer)
  setDisplayFlex(messageContainer)
  setAlignItemsCenter(messageContainer)
  return messageContainer
}

const addMessageToContainer = (message, container) => {
  const messageElement = document.createElement('span')
  setWidth(messageElement, '100vw')
  setPadding(messageElement, '0px 10px')
  setDisplayInlineBlock(messageElement)
  setTextAlignCenter(messageElement)
  messageElement.insertAdjacentHTML('afterbegin', message)
  container.appendChild(messageElement)
}

const stylePromoBanner = (container, { backgroundColor, textColor }) => {
  setWidth(container, `100%`)
  removeLeftPadding(container)
  removeRightPadding(container)
  container.style.backgroundColor = backgroundColor
  container.style.color = textColor
  container.innerHTML = ''
}

export const insertBannerHTML = ({
  backgroundColor = '#000000',
  textColor = '#FFFFFF',
  messages = [],
}) => {
  const bannerContainer = document.querySelector('.promo-banner')
  if (bannerContainer) {
    const messageContainer = createMessageContainer(
      `${messages.length * 100}vw`
    )
    stylePromoBanner(bannerContainer, { backgroundColor, textColor })
    for (const msg of messages) {
      addMessageToContainer(msg.message, messageContainer)
    }
    bannerContainer.appendChild(messageContainer)
    addPromoBannerEffects(messageContainer, messages.length)
  }
}

export const insertTbybHTML = (data) => {
  const desktopNav = document.querySelector('.navigation__desktop')
  if (data && desktopNav) {
    desktopNav.insertAdjacentHTML('beforeend', data)

    const desktopParentComponents = document.querySelectorAll(
      '.button-link__desktop-callout'
    )
    desktopParentComponents.forEach(function (component) {
      component.style.display = 'flex'
    })
  }
}

export const insertDesktopFeaturedContent = (desktopData) => {
  const desktopParentComponents = document.querySelectorAll(
    '.button-link__desktop-callout'
  )
  if (
    desktopData &&
    desktopParentComponents &&
    desktopParentComponents.length > 0
  ) {
    desktopParentComponents.forEach(function (component) {
      component.outerHTML = desktopData
    })
    const currentCalloutButtons = document.querySelectorAll('.tbyb-button')

    // Avoid open menu click
    if (currentCalloutButtons) {
      currentCalloutButtons.forEach(function (button) {
        button.addEventListener('click', function (e) {
          e.stopPropagation()
        })
      })
    }
  }
}

export const insertMobileFeaturedContent = (mobileData) => {
  const mobileParentComponent = document.querySelector(
    '.nav-link__mobile-callout'
  )
  if (mobileData && mobileParentComponent) {
    mobileParentComponent.style.gridTemplateColumns = '100%'
    mobileParentComponent.innerHTML = mobileData

    // Avoid open menu click
    const link = mobileParentComponent.querySelector('a')
    link &&
      link.addEventListener('click', function (e) {
        e.stopPropagation()
      })
  }
}

const getAccountElements = () => ({
  menu: document.querySelector('#menu'),
  searchContainer: document.querySelector('#searchContainer'),
  bottomRowAccount: document.querySelector(
    '.bottom-row__links-right__my-account'
  ),
})

export const getInsertedAccountElements = () => ({
  desktopMyAccount: document.querySelector('#nav-link__desktop--my-account'),
  menuLevels: document.querySelectorAll('.menu__container__top'),
  mobileMyAccountIcon: document.querySelector(
    '.nav-link__mobile--account > svg'
  ),
  mobileMyAccount: document.querySelector('#nav-link__mobile--sign-in'),
})

const removeMyAccountLoggedInClickEvent = (element) => {
  element.removeAttribute('href')
}

export const insertMyAccountHTML = (mobileSessionData, desktopSessionData) => {
  const { menu, searchContainer, bottomRowAccount } = getAccountElements()

  bottomRowAccount.classList.add('my-account--logged-in')
  removeMyAccountLoggedInClickEvent(bottomRowAccount)
  searchContainer &&
    searchContainer.insertAdjacentHTML('afterend', desktopMyAccountHTML)
  menu && menu.insertAdjacentHTML('afterbegin', mobileMyAccountHTML)

  const { desktopMyAccount, mobileMyAccount, mobileMyAccountIcon } =
    getInsertedAccountElements()

  mobileMyAccount &&
    mobileMyAccount.insertAdjacentHTML('afterend', mobileSessionData)

  mobileMyAccountIcon.classList.add('nav-link__mobile__icon--logged-in')

  const myAccountMenu = mobileMyAccount.nextElementSibling
  myAccountMenu.classList.add('my-account-menu')
  myAccountMenu.style.top = '100px'

  desktopMyAccount &&
    desktopMyAccount.insertAdjacentHTML('afterend', desktopSessionData)

  const mobileMyAccountMenu = mobileMyAccount.nextElementSibling
  mobileMyAccountMenu.classList.add('my-account-menu')
  mobileMyAccountMenu.style.top = '100px'
}

export const insertSignInHTML = () => {
  const { menu, searchContainer } = getAccountElements()

  searchContainer &&
    searchContainer.insertAdjacentHTML('afterend', desktopSignInHTML)
  menu && menu.insertAdjacentHTML('afterbegin', mobileSignInHTML)
}

const hidePossibleLink = (id) => {
  const menuLink = document.querySelector(`.nav-link__${id}-link`)
  if (menuLink) {
    menuLink.style.display = 'none'
  }
}

const makeSureMenuIsVisible = (id) => {
  const menuDiv = document.querySelector(`.nav-link__${id}`)
  if (menuDiv.style.display === 'none') {
    menuDiv.style.display = ''
  }
}

export const insertNavLinkHTML = (data) => {
  for (const [id, html] of Object.entries(data)) {
    if (!html) continue

    const dropdownDiv = document.querySelector(`#link-${id}`)
    const menuDiv = document.querySelector(`.nav-link__${id}`)

    if (dropdownDiv) {
      dropdownDiv.insertAdjacentHTML('beforeend', html)
    }

    if (menuDiv) {
      hidePossibleLink(id)
      makeSureMenuIsVisible(id)
      menuDiv.insertAdjacentHTML('afterend', html)
    }
  }
}

export const insertCartItemCount = () => {
  const desktopCartCountContainerEl = document.querySelector(
    '.nav-link__desktop__cart-count-container'
  )
  const mobileCartCountContainerEl = document.querySelector(
    '.nav-link__mobile__cart-count-container'
  )
  const desktopCartCountEl = document.querySelector(
    '.nav-link__desktop__cart-count'
  )
  const mobileCartCountEl = document.querySelector(
    '.nav-link__mobile__cart-count'
  )

  const cartCount = getCartCountFromCookie()

  if (cartCount && cartCount > 0) {
    desktopCartCountEl.innerHTML = cartCount
    mobileCartCountEl.innerHTML = cartCount
    setDisplayFlex(desktopCartCountContainerEl)
    setDisplayFlex(mobileCartCountContainerEl)
  }
}

export const insertFooterHTML = (data) => {
  for (const [id, html] of Object.entries(data)) {
    if (html) {
      document.querySelector(`#${id}`).innerHTML = html
    }
  }
}

export const checkIfFooterIsReady = (data) => {
  if (document.readyState === 'loading') {
    document.addEventListener(
      'DOMContentLoaded',
      function () {
        insertFooterHTML(data)
      },
      false
    )
  } else {
    insertFooterHTML(data)
  }
}

export const enableNewsletterInput = async () => {
  await waitForElementToExist('[data-dia-email-signup="1"]', '.k-footer')

  const newsletterForms =
    document.querySelectorAll('[data-dia-email-signup="1"]') || []
  newsletterForms.forEach((form) => {
    if (form.querySelector('input')) {
      form.querySelector('input').disabled = false
    }
    if (form.querySelector('[type=submit]')) {
      form.querySelector('[type=submit]').disabled = false
    }
  })
}

const waitForElementToExist = (
  selector,
  selectorToObserve,
  timeToWait = 10000
) => {
  if (!selector) return Promise.reject(null)

  return new Promise((resolve) => {
    // Define for how long will wait the element until give up
    setTimeout(() => {
      resolve(null)
    }, timeToWait)

    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector))
        observer.disconnect()
      }
    })

    const willObserve =
      document.querySelector(selectorToObserve) || document.body
    observer.observe(willObserve, { childList: true, subtree: true })
  })
}

export const insertMobileDiscountLine = async (discountLine) => {
  const discountLineElement = await waitForElementToExist(
    '#mobile-discount-line',
    '.mobile-navigation-menu'
  )
  if (discountLineElement && discountLine) {
    discountLineElement.outerHTML = discountLine
  }
}

const hideMobileDiscountLine = () => {
  const discountLineElement = document.querySelector('#mobile-discount-line')
  if (discountLineElement) {
    discountLineElement.style.display = 'none'
  }
}

const insertMobileMyAccountRewards = async (rewardsData) => {
  const {
    first_name: name,
    tier_name: tier,
    points_balance: points,
    reward_cents: reward,
  } = rewardsData

  const myAccountNameElement = await waitForElementToExist(
    '#mobile-myaccount-name'
  )
  if (myAccountNameElement && name) {
    myAccountNameElement.innerHTML = `Hi, ${name}`
  }

  const mobileRewardsElement = await waitForElementToExist(
    '#mobile-rewards-points'
  )
  if (mobileRewardsElement && tier) {
    hideMobileDiscountLine()
    const rewardText = reward
      ? `<span class="k-text--bold">$${reward / 100}</span>&nbsp;Reward`
      : `<span class="k-text--bold">${points}</span>&nbsp;Points`
    mobileRewardsElement.innerHTML = `${tier}<img alt="${tier}" src="${getRewardsIcon(
      tier
    )}" width="16px" height="13px" />${rewardText}`
  }
}

const insertDesktopMyAccountRewards = async (rewardsData) => {
  const {
    first_name: name,
    tier_name: tier,
    points_balance: points,
    reward_cents: reward,
  } = rewardsData

  const desktopMyAccountNameElement = await waitForElementToExist(
    '#desktop-myaccount-name'
  )
  if (desktopMyAccountNameElement && name) {
    desktopMyAccountNameElement.innerHTML = `Hi, ${name}`
  }

  const desktopRewardsElement = await waitForElementToExist(
    '#desktop-rewards-points'
  )
  if (desktopRewardsElement && tier) {
    const rewardText = reward
      ? `<span class="k-text--bold">$${reward / 100}</span>&nbsp;Reward`
      : `<span class="k-text--bold">${points}</span>&nbsp;Points`
    desktopRewardsElement.innerHTML = `${tier}<img alt="${tier}" src="${getRewardsIcon(
      tier
    )}" width="16px" height="13px" />${rewardText}`
  }
}

export const insertRewardsHTML = (rewardsData) => {
  if (!rewardsData) return

  insertMobileMyAccountRewards(rewardsData)
  insertDesktopMyAccountRewards(rewardsData)
}

export const setLogoImage = () => {
  const logoLinks = [
    document.querySelector('#main-logo'),
    document.querySelector('#main-logo-small-desktop'),
    document.querySelector('#main-logo-mobile'),
  ]
  logoLinks.forEach(function (logoLink) {
    if (logoLink) {
      const diaLogo = logoLink.querySelector('.dia-logo')
      const elevenHLogo = logoLink.querySelector('.elevenH-logo')
      if (
        window.location.pathname.indexOf('11honore') > -1 &&
        elevenHLogo.style.display === 'none'
      ) {
        diaLogo.style.display = 'none'
        elevenHLogo.style.display = 'inline-block'
      }
      if (
        window.location.pathname.indexOf('11honore') === -1 &&
        diaLogo.style.display === 'none'
      ) {
        elevenHLogo.style.display = 'none'
        diaLogo.style.display = 'inline-block'
      }
    }
  })
}

export const insertSeasonalHTML = () => {
  const topRowLinksDivDesktop = document.querySelector(
    '#desktop--seasonal-or-quiz'
  )
  const topRowLinksDivMobile = document.querySelector(
    '#mobile--seasonal-or-quiz'
  )
  topRowLinksDivDesktop?.appendChild(seasonalBoxAHTML())
  topRowLinksDivMobile?.appendChild(seasonalBoxMobileHTML())
}

export const insertQuizHTML = () => {
  const topRowLinksDivDesktop = document.querySelector(
    '#desktop--seasonal-or-quiz'
  )
  const topRowLinksDivMobile = document.querySelector(
    '#mobile--seasonal-or-quiz'
  )
  topRowLinksDivDesktop?.appendChild(takeQuizBtnHTML())
  const takeQuizBtn = takeQuizBtnHTML()
  takeQuizBtn.style.width = '100%'
  takeQuizBtn.style.marginLeft = '0'
  topRowLinksDivMobile?.appendChild(takeQuizBtn)
}

export const insertShopAllHTML = () => {
  const topRowLinksDivDesktop = document.querySelector('#desktop--shop-all')
  const topRowLinksDivMobile = document.querySelector('#mobile--shop-all')
  topRowLinksDivMobile?.appendChild(shopAllBtnHTML())
  const shopAllBtn = shopAllBtnHTML()
  shopAllBtn.style.height = '30px'
  shopAllBtn.style.width = '213px'
  shopAllBtn.lastChild.style.marginLeft = '0'
  shopAllBtn.style.display = 'inline-flex'
  topRowLinksDivDesktop?.appendChild(shopAllBtn)
}

export const seasonalBoxAHTML = () => {
  const seasonalBoxA = document.createElement('a')
  seasonalBoxA.className = 'k-px-3 k-pt-2 k-pb-2 k-text--sm k-text--bold'
  seasonalBoxA.id = 'top-row__link__desktop--seasonal-box'
  seasonalBoxA.innerText = 'Seasonal Boxes'
  seasonalBoxA.href = `${getEnvVar('baseUrlWWW')}/style-box#seasonal-boxes`
  seasonalBoxA.style.textAlign = 'center'
  seasonalBoxA.style.textDecoration = 'none'
  seasonalBoxA.style.marginLeft = '30px'
  return seasonalBoxA
}

export const seasonalBoxMobileHTML = () => {
  const seasonalBoxMoHTML = document.createElement('a')
  seasonalBoxMoHTML.className = 'nav-link__list-faqs'
  seasonalBoxMoHTML.id = 'top-row__link__desktop--seasonal-box'
  seasonalBoxMoHTML.innerHTML = `<div class="k-text--sm k-text--bold k-px-2">Seasonal Boxes</div>`
  seasonalBoxMoHTML.href = `${getEnvVar('baseUrlWWW')}/style-box#seasonal-boxes`
  return seasonalBoxMoHTML
}

export const takeQuizBtnHTML = () => {
  const takeQuizBtn = document.createElement('a')
  takeQuizBtn.className = 'k-px-3 k-pt-2 k-pb-2 k-text--sm k-text--bold'
  takeQuizBtn.id = 'top-row__link__desktop--take-quiz'
  takeQuizBtn.innerHTML = 'Take The Style Quiz'
  takeQuizBtn.href = `${getEnvVar('baseUrlWWW')}/style/survey#question=start`
  takeQuizBtn.style.backgroundColor = 'black'
  takeQuizBtn.style.color = 'white'
  takeQuizBtn.style.borderRadius = '4px'
  takeQuizBtn.style.border = 'black'
  takeQuizBtn.style.fontSize = '15px'
  takeQuizBtn.style.textAlign = 'center'
  takeQuizBtn.style.textDecoration = 'none'
  takeQuizBtn.style.marginLeft = '30px'
  return takeQuizBtn
}

export const shopAllBtnHTML = () => {
  const shopAllBtn = document.createElement('a')
  shopAllBtn.className =
    'tbyb-button k-px-3 k-pt-2 k-pb-2 k-text--sm k-text--bold'
  shopAllBtn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="14.855" height="18" viewBox="0 0 14.855 18">
  <path id="Union_3" data-name="Union 3" d="M6878.76-.766a.723.723,0,0,1-.239-.908l3.81-7.946-.467-2.107-1.892.739a.725.725,0,0,1-.9-.321l-.983-1.759a.723.723,0,0,1,.2-.933l3.794-2.844a.758.758,0,0,1,.447-.155.646.646,0,0,1,.188.027.7.7,0,0,1,.467.425,2.183,2.183,0,0,0,2.106,1h.006a2.2,2.2,0,0,0,2.116-1,.691.691,0,0,1,.437-.414.715.715,0,0,1,.61.082l4.051,2.5a.723.723,0,0,1,.277.92l-.975,2.1a.722.722,0,0,1-.945.359l-1.678-.729-.68,2.127,4.155,7.9a.721.721,0,0,1-.289.969A15.951,15.951,0,0,1,6885.3,1,12.133,12.133,0,0,1,6878.76-.766Zm1.356-.885a9.863,9.863,0,0,0,1.013.452,11.833,11.833,0,0,0,4.169.75,14.812,14.812,0,0,0,5.736-1.241l-3.572-6.791h-4.072Zm6.978-8.279.961-3a.725.725,0,0,1,.391-.438.727.727,0,0,1,.586-.006l1.764.766.406-.879-2.925-1.807A3.834,3.834,0,0,1,6885.3-14.1h-.008a3.834,3.834,0,0,1-2.931-1.145l-2.7,2.021.368.657,2.093-.817a.731.731,0,0,1,.6.036.726.726,0,0,1,.365.482l.651,2.936Z" transform="translate(-6877.999 17.001)" fill="#262626"/>
</svg>&nbsp
 Shop All Clothing &nbsp<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" style="margin-left: auto;">
  <g id="Group_10821" data-name="Group 10821" transform="translate(-1906.375 -4051.831)">
    <path id="Subtraction_23" data-name="Subtraction 23" d="M10.842,13.553H2.711A2.714,2.714,0,0,1,0,10.842V2.711A2.714,2.714,0,0,1,2.711,0H6.1V1.356H2.711A1.357,1.357,0,0,0,1.355,2.711v8.131A1.357,1.357,0,0,0,2.711,12.2h8.131A1.357,1.357,0,0,0,12.2,10.842V8.132h1.355v2.71A2.714,2.714,0,0,1,10.842,13.553Z" transform="translate(1906.375 4052.278)"/>
    <path id="Union_1" data-name="Union 1" d="M.2,8.33a.676.676,0,0,1,0-.958L6.216,1.355H3.394A.678.678,0,1,1,3.394,0H8.573V5.179a.678.678,0,1,1-1.355,0V2.269L1.157,8.33a.677.677,0,0,1-.958,0Z" transform="translate(1911.802 4051.831)"/>
  </g>
</svg>`
  shopAllBtn.href = `${getEnvVar('baseUrlShop')}?dia_style=true`
  shopAllBtn.target = '#'
  shopAllBtn.style.borderRadius = '60px'
  shopAllBtn.style.background = '#E8DED1 0% 0% no-repeat padding-box'
  shopAllBtn.style.border = 'black'
  shopAllBtn.style.fontSize = '14px'
  shopAllBtn.style.letterSpacing = '0.21px'
  shopAllBtn.style.color = '#262626'
  return shopAllBtn
}
